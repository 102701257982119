<template>
  <div class="footer" v-if="show">
    <div class="logo-item">
      <div class="logo-left">
        <img class="f-logo shlogo" :src="require('@/assets/img/logo.png')" />
        <div class="border"></div>
        <img class="f-logo" :src="require('@/assets/img/kylogo.png')" />
      </div>
      <div class="line1">
        <span class="t-btn" @click="handleGo(4, '/auto')">关于公司</span>
        <span class="line">|</span>
        <span class="t-btn" @click="handleGo(2, '/GameList')">盛和游戏</span>
        <span class="line">|</span>
        <span class="t-btn" @click="handleGo(5, '/join')">加入盛和</span>
        <span class="line">|</span>
        <span class="t-btn" @click="handleGo(6, '/contact')">联系我们</span>
      </div>
    </div>
    <div class="text pc" style="padding-top: 10px">
      <p>
        健康游戏忠告：抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。适度游戏益脑，
        沉迷游戏伤身。合理安排时间，享受健康生活。
      </p>
      <p>
        <a style="color: #2e2e2e" href="/static/treaty.html"
          >本公司积极履行《网络游戏行业防沉迷自律公约》</a
        >
        <span class="line">|</span>
        适龄提示：本平台游戏适用于年满18周岁及以上的用户
      </p>
      <p>
        违法和不良信息举报电话：021-62205351
        <span class="line">|</span>
        <a
          style="color: #2e2e2e"
          href="http://www.shdf.gov.cn/shdf/channels/740.html"
          >扫黄打非举报</a
        >
        <span class="line">|</span>
        <a style="color: #2e2e2e" href="http://jbts.mct.gov.cn/"
          >全国文化市场统一举报电话：12318</a
        >
      </p>
      <p>
        亲爱的市民朋友，浙江警方反诈劝阻电话“96110”系专门针对避免您财产被骗受损而设，请您一旦收到来电，立即接听。
      </p>
      <p>
        <a
          style="color: #2e2e2e"
          href="https://beian.miit.gov.cn/#/Integrated/index"
          >浙ICP备11040437号</a
        >
        <span class="line">|</span>
        浙网文[2019]1202-109号
        <span class="line">|</span>
        浙B2-20120032
        <span class="line">|</span>
        版权所有©2011-2024 浙江盛和网络科技有限公司
      </p>
    </div>

    <div class="text h5">
      <p>
        健康游戏忠告：抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。
      </p>
      <p>适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。</p>
      <p>
        <a style="color: #2e2e2e" href="/static/treaty.html"
          >本公司积极履行《网络游戏行业防沉迷自律公约》</a
        >
      </p>
      <p>适龄提示：本平台游戏适用于年满18周岁及以上的用户</p>
      <p>
        违法和不良信息举报电话：021-62205351 |
        <a
          style="color: #2e2e2e"
          href="http://www.shdf.gov.cn/shdf/channels/740.html"
          >扫黄打非举报</a
        >
        |
      </p>
      <p>
        亲爱的市民朋友，浙江警方反诈劝阻电话“96110”系专门针对避免您财产被骗受损而设，请您一旦收到来电，立即接听。
      </p>
      <p>
        <a
          style="color: #2e2e2e"
          href="https://beian.miit.gov.cn/#/Integrated/index"
          >浙ICP备11040437号</a
        >
        <span class="line">|</span>
        浙网文[2019]1202-109号
        <span class="line">|</span>
        浙B2-20120032
      </p>
      <p>
        <a style="color: #2e2e2e" href="http://jbts.mct.gov.cn/"
          >全国文化市场统一举报电话：12318</a
        >
      </p>
      <p>Copyright Dream2 2011 All Right Reserved</p>
      <p>浙江盛和网络科技有限公司 版权所有</p>
    </div>

    <div class="b-item" v-if="!ifH5()">
      <div class="item item1">
        <img class="fiimg" :src="require('@/assets/img/fi1.png')" />
        <div class="fi-font">互联网文化<br />经营单位</div>
      </div>
      <div class="item item2">
        <img class="fiimg" :src="require('@/assets/img/fi2.png')" />
        <div class="fi-font">
          <a style="color: #2e2e2e" href="https://www.12377.cn/"
            >网上有害信息<br />举报专区</a
          >
        </div>
      </div>
      <div class="item item3">
        <img class="fiimg" :src="require('@/assets/img/fi3.png')" />
        <div class="fi-font">
          <a style="color: #2e2e2e" href="https://www.zjjubao.com/"
            >浙江互联网违法和<br />不良信息举报中心</a
          >
        </div>
      </div>
      <div class="item item4">
        <img class="fiimg" :src="require('@/assets/img/fi4.png')" />
        <div class="fi-font">
          <a
            style="color: #2e2e2e"
            href="https://beian.mps.gov.cn/#/query/webSearch?code=33068302000795"
            >浙公网安备<br />33068302000795号</a
          >
        </div>
      </div>
    </div>
    <div class="b-item" v-else>
      <div class="h5-item b1">
        <div class="item item1">
          <img class="fiimg" :src="require('@/assets/img/fi1.png')" />
          <div class="fi-font">互联网文化经营单位</div>
        </div>
        <div class="item item2">
          <img class="fiimg" :src="require('@/assets/img/fi2.png')" />
          <div class="fi-font">
            <a style="color: #2e2e2e" href="https://www.12377.cn/"
              >网上有害信息举报专区</a
            >
          </div>
        </div>
      </div>
      <div class="h5-item b2">
        <div class="item item3">
          <img class="fiimg" :src="require('@/assets/img/fi3.png')" />
          <div class="fi-font">
            <a style="color: #2e2e2e" href="https://www.zjjubao.com/"
              >浙江互联网违法和不良信息举报中心</a
            >
          </div>
        </div>
        <div class="item item4">
          <img class="fiimg" :src="require('@/assets/img/fi4.png')" />
          <div class="fi-font">
            <a
              style="color: #2e2e2e"
              href="https://beian.mps.gov.cn/#/query/webSearch?code=33068302000795"
              >浙公网安备33068302000795号</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, computed, watch } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
import { useStore } from "vuex";
const store = useStore();
const show = ref(false);
const load = computed(() => store.state.load);
watch(load, (newV) => {
  if (newV == false) {
    setTimeout(() => {
      show.value = true;
    }, 1000);
  } else {
    show.value = false;
  }
});
// 判断是否移动端
const ifH5 = () => {
  if (
    navigator.userAgent.match(/Mobi/i) ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPhone/i)
  ) {
    return true;
  } else {
    return false;
  }
};
const handleGo = (n, str, query) => {
  console.log(n, str);
  router.push({
    path: str,
    query: query,
  });
  store.commit("setActiveIndex", n);
};
onMounted(async () => {});

onUnmounted(() => {});
</script>

<style scoped>
.logo-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #dbdbdb;
}
.logo-left {
  display: flex;
  align-items: center;
}
.f-logo {
  width: 150px;
}
.border {
  height: 25px;
  margin: 0 30px;
  background-color: #75777a;
  width: 1px;
}
.shlogo {
}
.footer {
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 0 calc(50% - 500px);
  background: #fff;
  height: 350px;
  font-size: 14px;
  color: #2e2e2e;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
}
.b-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 700px;
  margin-top: 10px;
}
.item {
  /* width: 190px; */
  height: 100%;
  display: flex;
  padding: 5px;
  padding-right: 10px;
  align-items: center;
  box-sizing: border-box;
  text-align: left;
}
/* .item1 {
  width: 200px;
} */
.item4 {
  border-right: none;
}
.fiimg {
  width: 40px;
  margin-right: 5px;
}
p {
  line-height: 25px;
}
.line1 {
  margin-top: 30px;
}
.text {
  text-align: left;
  line-height: 20px;
}
.line {
  margin: 0 10px;
}
.t-btn {
  cursor: pointer;
}
.t-btn:hover {
  color: #cc2908;
}
@media screen and (max-width: 750px) {
  .logo-item {
    flex-direction: column;
  }
  .footer {
    flex-direction: column;
    justify-content: center;
    height: 600px;
    width: 100%;
    padding: 0 0;
  }
  .logo {
    width: 1rem;
    margin-bottom: 10px;
  }
  .text {
    padding: 0 0.2rem;
    padding-top: 0.3rem;
    font-size: 0.22rem;
    text-align: center;
  }
  .footimg {
    width: 6.5rem;
  }
  .b-item {
    width: 6.5rem;
    display: block;
    font-size: 0.22rem;
    padding-left: 0.5rem;
  }
  .h5-item {
    display: flex;
  }
  .item {
    flex: 1;
    padding: 0.1rem;
    height: 0.8rem;
  }
  .fiimg {
    width: 0.3rem;
  }
  .item2 {
    border-right: none;
  }
  .b1 {
  }
  .fi-font {
    text-align: center;
    flex: 1;
  }
  .f-logo {
    width: 2rem;
  }
  .shlogo {
    margin-right: 0.3rem;
  }
}
</style>
