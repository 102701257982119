<template>
  <div class="home" id="myElement">
    <div class="section section1">
      <img class="homebg1 pc" :src="require('@/assets/img/newsbg1.jpg')" />
      <img class="homebg1 h5" :src="require('@/assets/imgh5/newsbg1.jpg')" />
      <img class="div2" :src="require('@/assets/img/div2.png')" />
      <img class="div3" :src="require('@/assets/img/div3.png')" />

      <img class="h3dimg" :src="require('@/assets/img/newtitle.png')" />
    </div>

    <div class="section section2">
      <div class="search-item">
        <el-date-picker
          @change="getNews"
          class="search-input"
          clearable
          v-model="search.time"
          type="month"
          placeholder="时间选择"
        />
        <el-input
          @input="getNews"
          class="search-input"
          :prefix-icon="Search"
          v-model="search.text"
          placeholder="关键字搜索"
        ></el-input>
      </div>
      <div class="contain">
        <div
          class="news-item"
          v-for="(item, index) in showNewsList"
          :key="index"
          @click="handleGoDetail(item)"
        >
          <img class="n-img" :src="item.cover" />

          <div class="news-contain">
            <img class="newsbg" :src="require('@/assets/img/hwbg.png')" />
            <!-- 新闻内容 -->
            <div class="news-text">
              <div class="news-top">
                <div class="news-time">
                  {{ dateFormat(item.edit_time + "000", "/") }}
                </div>
                <div class="news-title">{{ item.title }}</div>
              </div>
              <div class="new-desc">
                {{ item.precis }}
              </div>
              <div class="readmore">
                <img
                  class="rmicon"
                  :src="require('@/assets/img/readmore.png')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="more-b">
        <div class="more-btn" v-if="page < totalPage" @click="getMore">
          查看更多
        </div>
        <div class="nomore" v-if="page >= totalPage && totalPage > 1">
          没有更多了~
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { dateFormat } from "@/utils/dateFormat";

import { Search } from "@element-plus/icons-vue";
import { ref, onMounted, onUnmounted, getCurrentInstance } from "vue";
import { useStore } from "vuex";
const store = useStore();
store.commit("setLoad", true);
const { proxy } = getCurrentInstance();
// 查询
const search = ref({
  time: "",
  text: "",
});
// 初始化数据
const webId = ref();
const showNewsList = ref([]);
const newsList = ref([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
const page = ref(1);
const totalPage = ref(1);
// 判断是否移动端
const ifH5 = () => {
  if (
    navigator.userAgent.match(/Mobi/i) ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPhone/i)
  ) {
    return true;
  } else {
    return false;
  }
};
// 展示哪些新闻
const getShowList = (n) => {
  for (let i = 0; i < n; i++) {
    if (newsList.value[(page.value - 1) * n + i]) {
      showNewsList.value.push(newsList.value[(page.value - 1) * n + i]);
    }
  }
};
// 初始化
const initData = async () => {
  return new Promise((reslove) => {
    let params = {
      host: window.location.host, //"www.sh.com",
    };
    proxy.$axios.post("/Index/initData", params).then(async (res) => {
      if (res.code == 200) {
        webId.value = res.data.company_id;
        await getNews();
        reslove();
      }
    });
  });
};
// 获取最新资讯
const getNews = async () => {
  return new Promise((reslove) => {
    let timeArr = getCurrentYearMonth(search.value.time);
    let timer = getMonthTimestamps(timeArr[0], timeArr[1]);
    let params = {
      company_id: webId.value,
      type: "list",
      title: search.value.text,
      time: timer.startTimestamp
        ? [timer.startTimestamp / 1000, timer.endTimestamp / 1000].toString()
        : "",
    };
    proxy.$axios.post("/Index/getNews", params).then((res) => {
      if (res.code == 200) {
        newsList.value = res.data;
        showNewsList.value = [];
        // 获取展示新闻
        if (ifH5()) {
          totalPage.value = Math.ceil(newsList.value.length / 4);
          getShowList(4);
        } else {
          totalPage.value = Math.ceil(newsList.value.length / 4);
          getShowList(4);
        }
        reslove();
      }
    });
  });
};
// 获取当前年月的方法
const getCurrentYearMonth = (val) => {
  if (!val) return [];
  const now = val;
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0"); // 月份是从0开始的
  return [year, month];
};
// 获取某一个月份的时间戳
const getMonthTimestamps = (year, month) => {
  if (!year && !month) return {};
  // 初始化日期为该月的第一天
  let startDate = new Date(year, month - 1, 1);
  let startTimestamp = startDate.getTime(); // 获取初始时间戳

  // 初始化日期为下个月的第一天
  let endDate = new Date(year, month, 1);
  endDate.setMilliseconds(-1); // 将时间设置为上个月的最后一秒
  let endTimestamp = endDate.getTime(); // 获取结束时间戳

  return {
    startTimestamp: startTimestamp,
    endTimestamp: endTimestamp,
  };
};
// 查看更多
const getMore = () => {
  page.value = page.value + 1;
  if (ifH5()) {
    getShowList(4);
  } else {
    getShowList(4);
  }
};
// 查看详情
const handleGoDetail = (item) => {
  proxy.$router.push({
    path: "/newsDetail",
    query: { id: item.id },
  });
};
onMounted(async () => {
  store.commit("setLoad", false);
  await initData();
});

onUnmounted(() => {});
</script>
<style src="./news.css" scoped></style>
<style>
.el-input__wrapper.is-focus {
  box-shadow: 0 0 0 1px #cc2908 inset;
}
.el-month-table td.today .cell {
  color: #cc2908;
}
.el-month-table td.current:not(.disabled) .cell {
  color: #cc2908;
}
.el-date-picker {
  --el-datepicker-hover-text-color: #cc2908;
}
</style>
  