<template>
  <div class="home" id="myElement">
    <div class="section section3">
      <div class="contain contain2">
        <div class="btn" @click="handleBack">返回</div>
        <div class="new-title">{{ detail.title }}</div>
        <div class="new-time">
          {{ detail.edit_time }}
        </div>
        <div class="news-detail" v-html="detail.content"></div>
      </div>
    </div>
  </div>
</template>
    
  <script setup>
import { ref, onMounted, onUnmounted, getCurrentInstance, computed } from "vue";
const { proxy } = getCurrentInstance();
// query处理
const show = computed(() => proxy.$route.query.id);
const detail = ref({});
// 查看详情
const getDetail = () => {
  let params = {
    n_id: show.value,
  };
  proxy.$axios.post("/Index/getNewsContent", params).then(async (res) => {
    if (res.code == 200) {
      detail.value = res.data;
    }
  });
};
getDetail();
// 返回
const handleBack = () => {
  proxy.$router.go(-1);
};
onMounted(async () => {});

onUnmounted(() => {});
</script>
  <style src="./news.css" scoped></style>
  <style>
</style>
    