<template>
  <div class="auto_css" id="myElement">
    <div class="section1 section" id="section1">
      <img
        class="pc"
        :src="require('@/assets/img/ab1.jpg')"
        style="width: 100%; opacity: 0"
      />
      <img
        class="h5"
        :src="require('@/assets/imgh5/ab1.jpg')"
        style="width: 100%; opacity: 0"
      />
      <van-swipe
        class="swipe"
        ref="swipe"
        :autoplay="false"
        :show-indicators="false"
        @change="onChange"
        lazyRender
      >
        <van-swipe-item v-for="item in bannerList" :key="item">
          <!-- pc -->
          <img
            v-if="!ifH5()"
            :src="require(`@/assets/img/ab${item}.jpg`)"
            style="width: 100%"
          />
          <!-- h5 -->
          <img
            v-else
            :src="require(`@/assets/imgh5/ab${item}.jpg`)"
            style="width: 100%"
          />
        </van-swipe-item>
      </van-swipe>
      <!-- 切换按钮 -->
      <div class="swiper-btn">
        <img
          class="s-btn prev"
          :src="require('@/assets/img/aleft.png')"
          @click="bannerPrev"
        />
        <div class="index-font">{{ indexImg + 1 }}/7</div>
        <img
          class="s-btn next"
          :src="require('@/assets/img/aright.png')"
          @click="bannerNext"
        />
      </div>

      <img class="div1 pc" :src="require('@/assets/img/div1.png')" />
    </div>
    <div class="section2 section" id="section2" :key="styleKey">
      <img class="aboutbg2 pc" :src="require('@/assets/img/allbg.jpg')" />
      <img class="aboutbg2 h5" :src="require('@/assets/imgh5/aboutbg2.jpg')" />
      <img
        class="div1 pc"
        v-if="showDiv != 4"
        :src="require('@/assets/img/div1.png')"
      />
      <div class="top-contain" ref="showDiv1">
        <img class="tc1" :src="require('@/assets/img/aben.png')" />
        <img class="tc2 titleicon" :src="require('@/assets/img/yjicon.png')" />
        <img class="tc3 pc" :src="require('@/assets/img/ab2left.png')" />
        <img class="tc3 h5" :src="require('@/assets/imgh5/ab2left.png')" />
        <div class="tc-font pc">
          <span class="hui-font">成为一家具备完整游戏生态链的公司 // </span>
          <span class="red-font">让快乐更多元</span>
        </div>
        <div class="tc-font h5">
          <span class="hui-font">成为一家具备完整游戏生态链的公司</span><br />
          <span class="red-font">让快乐更多元</span>
        </div>
      </div>
      <div class="bottom-contain" ref="showDiv2">
        <img
          :key="styleKey2"
          class="bc1 titleicon"
          :src="require('@/assets/img/lnicon.png')"
        />
        <img class="bc2 pc" :src="require('@/assets/img/lnbg.png')" />

        <img
          :key="styleKey2"
          class="bc3 lnimg"
          :src="require('@/assets/img/ln1.jpg')"
        />
        <img
          :key="styleKey2"
          class="bc4 lnimg"
          :src="require('@/assets/img/ln2.jpg')"
        />
        <img
          :key="styleKey2"
          class="bc5 lnimg"
          :src="require('@/assets/img/ln3.jpg')"
        />
      </div>
      <div class="section3 section pc" id="section3" ref="showDiv3">
        <img
          :key="styleKey3"
          class="lcicon titleicon"
          :src="require('@/assets/img/lcicon.png')"
        />

        <div class="time-line" :key="styleKey3">
          <img
            class="t-btn"
            :class="nowLine == 0 ? 'hui-b' : ''"
            :src="require('@/assets/img/t-left.png')"
            @click="timePrev"
          />
          <img
            class="timeline pc"
            :src="require('@/assets/img/timeline.png')"
          />
          <img
            class="timeline h5"
            :src="require('@/assets/imgh5/timeline.png')"
          />
          <img
            class="t-btn next"
            :class="nowLine == 14 ? 'hui-b' : ''"
            :src="require('@/assets/img/t-right.png')"
            @click="timeNext"
          />
          <div class="time-border">
            <img
              class="timehuan"
              ref="timeHuanpc"
              :src="require('@/assets/img/timehuan.png')"
            />
            <div class="time-div" ref="timelinepc">
              <div class="time-item prev-time">
                <img class="timed" :src="require('@/assets/img/timed.png')" />
                <div class="time"></div>
              </div>
              <div
                v-for="(item, index) in timeLine"
                :key="index"
                class="time-item"
                :class="[
                  item.id == nowLine ? 'active-time' : '',
                  item.id == nowLine - 1 ? 'prev-time' : '',
                  item.id == nowLine + 1 ? 'next-time' : '',
                ]"
              >
                <!-- <img class="timebg" :src="require('@/assets/img/timehuan.png')" /> -->
                <img class="timed" :src="require('@/assets/img/timed.png')" />
                <div class="time">{{ item.years }}</div>
              </div>
              <div class="time-item next-time">
                <img class="timed" :src="require('@/assets/img/timed.png')" />
                <!-- <div class="time">2014</div> -->
              </div>
            </div>
          </div>
        </div>

        <img
          class="time-img"
          :key="styleKey3"
          :src="require(`@/assets/timeline/${timeLine[nowLine].years}.png`)"
        />
        <div class="time-font" :key="styleKey3">
          {{ timeLine[nowLine].text }}
        </div>
      </div>
      <div class="section4 section pc" id="section4" ref="showDiv4">
        <img
          :key="styleKey4"
          class="jxicon titleicon"
          :src="require('@/assets/img/jxicon.png')"
        />

        <div class="w-jiang jbg" :key="styleKey4">
          <img class="jimgbg" :src="require('@/assets/img/jbg1.png')" />

          <div class="containj">
            <div class="j-li" v-for="(item, index) in prizeList1" :key="index">
              <img class="rd" :src="require('@/assets/img/rd.png')" />
              <div class="name">
                <div>{{ item.name }}</div>
                <div class="xie">/</div>
              </div>
              <div class="desc">
                <div v-for="(item2, index2) in item.desc" :key="index2">
                  {{ item2 }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="r-jiang jbg" :key="styleKey4">
          <img class="jimgbg" :src="require('@/assets/img/jbg2.png')" />
          <div class="containj">
            <div class="j-li" v-for="(item, index) in prizeList2" :key="index">
              <div class="name">
                <div>{{ item.name }}</div>
                <div class="xie">/</div>
              </div>
              <div class="desc">
                <div
                  class="desc-li"
                  v-for="(item2, index2) in item.desc"
                  :key="index2"
                >
                  {{ item2 }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section3 section h5" id="section3">
      <!-- <img class="aboutbg2 pc" :src="require('@/assets/img/aboutbg3.jpg')" /> -->
      <img class="aboutbg2 h5" :src="require('@/assets/imgh5/aboutbg3.jpg')" />
      <img class="lcicon titleicon" :src="require('@/assets/img/lcicon.png')" />

      <div class="time-line">
        <img
          class="t-btn"
          :class="nowLine == 0 ? 'hui-b' : ''"
          :src="require('@/assets/img/t-left.png')"
          @click="timePrev"
        />
        <img class="timeline pc" :src="require('@/assets/img/timeline.png')" />
        <img
          class="timeline h5"
          :src="require('@/assets/imgh5/timeline.png')"
        />
        <img
          class="t-btn next"
          :class="nowLine == 14 ? 'hui-b' : ''"
          :src="require('@/assets/img/t-right.png')"
          @click="timeNext"
        />
        <div class="time-border">
          <img
            class="timehuan"
            ref="timeHuan"
            :src="require('@/assets/img/timehuan.png')"
          />
          <div class="time-div" ref="timeline">
            <div class="time-item prev-time">
              <img class="timed" :src="require('@/assets/img/timed.png')" />
              <div class="time"></div>
            </div>
            <div
              v-for="(item, index) in timeLine"
              :key="index"
              class="time-item"
              :class="[
                item.id == nowLine ? 'active-time' : '',
                item.id == nowLine - 1 ? 'prev-time' : '',
                item.id == nowLine + 1 ? 'next-time' : '',
              ]"
            >
              <!-- <img class="timebg" :src="require('@/assets/img/timehuan.png')" /> -->
              <img class="timed" :src="require('@/assets/img/timed.png')" />
              <div class="time">{{ item.years }}</div>
            </div>
            <div class="time-item next-time">
              <img class="timed" :src="require('@/assets/img/timed.png')" />
              <!-- <div class="time">2014</div> -->
            </div>
          </div>
        </div>
      </div>

      <img
        class="time-img"
        :src="require(`@/assets/timeline/${timeLine[nowLine].years}.png`)"
      />
      <div class="time-font">{{ timeLine[nowLine].text }}</div>
    </div>
    <div class="section4 section h5" id="section4">
      <!-- <img class="aboutbg2 pc" :src="require('@/assets/img/aboutbg4.jpg')" /> -->
      <img class="aboutbg2 h5" :src="require('@/assets/imgh5/aboutbg4.jpg')" />
      <img class="jxicon titleicon" :src="require('@/assets/img/jxicon.png')" />

      <div class="w-jiang jbg">
        <img class="jimgbg" :src="require('@/assets/img/jbg1.png')" />

        <div class="containj">
          <div class="j-li" v-for="(item, index) in prizeList1" :key="index">
            <img class="rd" :src="require('@/assets/img/rd.png')" />
            <div class="name">
              <div>{{ item.name }}</div>
              <div class="xie">/</div>
            </div>
            <div class="desc">
              <div v-for="(item2, index2) in item.desc" :key="index2">
                {{ item2 }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="r-jiang jbg">
        <img class="jimgbg" :src="require('@/assets/img/jbg2.png')" />
        <div class="containj">
          <div class="j-li" v-for="(item, index) in prizeList2" :key="index">
            <div class="name">
              <div>{{ item.name }}</div>
              <div class="xie">/</div>
            </div>
            <div class="desc">
              <div
                class="desc-li"
                v-for="(item2, index2) in item.desc"
                :key="index2"
              >
                {{ item2 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  getCurrentInstance,
  onUnmounted,
  computed,
  watch,
} from "vue";
import { useStore } from "vuex";
const store = useStore();
store.commit("setLoad", true);
const { proxy } = getCurrentInstance();
const canRun = ref(true);
// 动画重载
const styleKey = ref(0);
// 中间滑动
const showDiv = ref(1);
const showDiv1 = ref();
const showDiv2 = ref();
const showDiv3 = ref();
const showDiv4 = ref();
// 样式触发
const styleKey2 = ref(0);
const styleKey3 = ref(0);
const styleKey4 = ref(0);
// page几
const $index1 = computed(() =>
  proxy.$route.query.page ? Number(proxy.$route.query.page) : 1
);
const $index = ref($index1.value);
watch($index1, (newV) => {
  $index.value = newV;
  if (newV == 2) {
    if (showDiv.value != 4) {
      styleKey.value = styleKey.value + 1;
    }
  }
});
// 轮播
const swipeTimer = ref();
const indexImg = ref(0);
const swipe = ref(null);
const autoplay = ref(5000);
const bannerList = ref([1, 2, 3, 4, 5, 6, 7]);
// 发展历程
const timeline = ref();
const timelinepc = ref();
const nowLine = ref(14);
const timeLine = ref([
  { id: 0, years: "2011.07", text: "浙江盛和网络科技有限公司成立" },
  { id: 1, years: "2016.04", text: "《蓝月传奇》测试火爆，成为里程碑式产品" },
  { id: 2, years: "2016.11", text: "《梁山传奇》上线测试，成绩良好" },
  { id: 3, years: "2017.01", text: "《王者传奇》成为公司标志性手游产品" },
  { id: 4, years: "2017.05", text: "《龙腾传世》页游推出" },
  { id: 5, years: "2018.03", text: "盛和大家庭500人，共创未来" },
  { id: 6, years: "2019.02", text: "《蓝月传奇3D》由腾讯独家代理" },
  { id: 7, years: "2020.02", text: "和恺英联合发行《高能手办团》" },
  { id: 8, years: "2020.09", text: "《蓝月传奇2》由腾讯独家代理" },
  { id: 9, years: "2021.09", text: "推出《狂暴传奇》" },
  { id: 10, years: "2022.04", text: "《凤凰传奇》上线，由贪玩发行" },
  { id: 11, years: "2022.09", text: "《永恒联盟》开启新品类线" },
  { id: 12, years: "2023.05", text: "恺英网络完成浙江盛和100%控股" },
  { id: 13, years: "2023.08", text: "《全民江湖》武侠MMORPG上线" },
  { id: 14, years: "2023.10", text: "《石器时代:觉醒》和腾讯合作发行" },
]);
const timeHuan = ref();
const timeHuanpc = ref();
const rotate = ref(0);
// 奖项
const prizeList1 = ref([
  {
    name: "烈焰皇朝",
    desc: ["2023年度硬核联盟5月度明星游戏"],
  },
  {
    name: "烈焰天下",
    desc: ["2022年度硬核联盟12月度明星游戏"],
  },
  {
    name: "龙城决",
    desc: ["2022年度硬核联盟8月度明星游戏"],
  },
  {
    name: "永恒联盟",
    desc: ["2022年度硬核联盟9月月度明星游戏"],
  },
  {
    name: "狂暴传奇",
    desc: [
      "华为游戏中心优秀联合推广游戏",
      "vivo年度最佳角色扮演游戏",
      "华为游戏中心全方位合作伙伴奖",
    ],
  },
]);
const prizeList2 = ref([
  {
    name: "盛和游戏",
    desc: [
      "华为游戏中心云云协同伙伴奖",
      "易盾五周年度品牌影响力奖",
      "华为Game+最佳商业创新奖",
      "华为游戏中心创新联运合作伙伴奖",
      "华为游戏中心云云协同伙伴奖",
      "华为年度云云共创最具远见奖",
      "OPPOHDC22开发者大会最具潜力小游戏奖",
    ],
  },
]);
// 判断是否移动端
const ifH5 = () => {
  if (
    navigator.userAgent.match(/Mobi/i) ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPhone/i)
  ) {
    return true;
  } else {
    return false;
  }
};
// 时间线点击
const timeNext = () => {
  if (nowLine.value == 14) return;
  nowLine.value = nowLine.value + 1;
  if (!ifH5()) {
    timelinepc.value.style.transform = `translateX(-${nowLine.value * 20.5}vw)`;
    rotate.value = rotate.value + 1;
    timeHuanpc.value.style.transform = `translate(0, 0) rotate(${
      rotate.value * 360
    }deg)`;
  } else {
    timeline.value.style.transform = `translateX(-${nowLine.value * 1.76}rem)`;
    rotate.value = rotate.value + 1;
    timeHuan.value.style.transform = `translate(0, 0) rotate(${
      rotate.value * 360
    }deg)`;
  }
};
const timePrev = () => {
  if (nowLine.value == 0) return;
  nowLine.value = nowLine.value - 1;
  if (!ifH5()) {
    timelinepc.value.style.transform = `translateX(-${nowLine.value * 20.5}vw)`;
    rotate.value = rotate.value - 1;
    timeHuanpc.value.style.transform = `translate(0, 0) rotate(${
      rotate.value * 360
    }deg)`;
  } else {
    timeline.value.style.transform = `translateX(-${nowLine.value * 1.76}rem)`;
    rotate.value = rotate.value - 1;
    timeHuan.value.style.transform = `translate(0, 0) rotate(${
      rotate.value * 360
    }deg)`;
  }
};
// 轮播
const startAutoplay = () => {
  swipeTimer.value = setInterval(() => {
    if (indexImg.value < bannerList.value.length - 1) {
      indexImg.value = indexImg.value + 1;
    } else {
      indexImg.value = 0;
    }
    toSwipe(indexImg.value == 0 ? bannerList.value.length : indexImg.value);
  }, autoplay.value);
};
const onChange = (index) => {
  if (swipeTimer.value) {
    clearInterval(swipeTimer.value);
    startAutoplay();
  }
  indexImg.value = index;
};
const toSwipe = (index) => {
  swipe.value.swipeTo(index);
};
const bannerPrev = () => {
  swipe.value.prev();
};
const bannerNext = () => {
  swipe.value.next();
};
// 鼠标滑动监测
const handleWheel = (e) => {
  if (ifH5()) return;
  e.preventDefault();
  if (canRun.value) {
    canRun.value = false;
    goScroll(e);
    setTimeout(() => {
      canRun.value = true;
    }, 500);
  }
};
const goScroll = (e) => {
  //e.wheelDelta 用来判断上一个下一个 <0 下一个 >0上一个
  if (e.wheelDelta < 0) {
    if ($index.value == 2 && showDiv.value < 4) {
      proxy.$refs[`showDiv${showDiv.value}`].style.transform =
        "translateY(-200%)";
      proxy.$refs[`showDiv${showDiv.value}`].style.opacity = 0;
      proxy.$refs[`showDiv${showDiv.value + 1}`].style.transform =
        "translateY(0%)";
      proxy.$refs[`showDiv${showDiv.value + 1}`].style.opacity = 1;
      showDiv.value = showDiv.value + 1;
      if (showDiv.value == 2) {
        styleKey2.value++;
      } else if (showDiv.value == 3) {
        styleKey3.value++;
      } else if (showDiv.value == 4) {
        styleKey4.value++;
      }
      return;
    }
    next();
  } else {
    if ($index.value == 2 && showDiv.value > 1) {
      proxy.$refs[`showDiv${showDiv.value}`].style.transform =
        "translateY(200%)";
      proxy.$refs[`showDiv${showDiv.value}`].style.opacity = 0;

      proxy.$refs[`showDiv${showDiv.value - 1}`].style.transform =
        "translateY(0%)";
      proxy.$refs[`showDiv${showDiv.value - 1}`].style.opacity = 1;

      showDiv.value = showDiv.value - 1;
      return;
    }
    last();
  }
};
// 下一个
const next = () => {
  if ($index.value < 2) {
    var element = document.getElementById(`section${$index.value}`);
    // 获取元素的总高度（包括内边距、边框和滚动条）
    var totalHeight = element.offsetHeight;
    window.scrollBy({ top: totalHeight, behavior: "smooth" });
    $index.value++;
    proxy.$router.replace({
      path: "/auto",
      query: { page: $index.value },
    });
  } else if ($index.value == 2) {
    $index.value++;
    window.scrollBy({ top: ifH5() ? 500 : 400, behavior: "smooth" });
    proxy.$router.replace({
      path: "/auto",
      query: { page: $index.value },
    });
  }
};
// 上一个
const last = () => {
  if ($index.value == 3) {
    $index.value--;
    window.scrollBy({
      top: Number(`-${ifH5() ? 500 : 400}`),
      behavior: "smooth",
    });
    proxy.$router.replace({
      path: "/auto",
      query: { page: $index.value },
    });
    return;
  }
  if ($index.value > 1) {
    $index.value--;
    proxy.$router.replace({
      path: "/auto",
      query: { page: $index.value },
    });
    var element = document.getElementById(`section${$index.value}`);
    // 获取元素的总高度（包括内边距、边框和滚动条）
    var totalHeight = element.offsetHeight;
    window.scrollBy({ top: -totalHeight, behavior: "smooth" });
  }
};
onMounted(async () => {
  window.addEventListener("wheel", handleWheel, { passive: false });
  startAutoplay();
  store.commit("setLoad", false);
});

onUnmounted(() => {
  window.removeEventListener("wheel", handleWheel);
  clearInterval(swipeTimer.value);
});
</script>
<style src="./auto.css" scoped></style>
<style scoped>
</style>
