<template>
  <div
    class="affix-container pc show"
    :class="ifVideo && activeIndex == 1 ? 'show-down' : 'show-up'"
  >
    <el-menu
      :default-active="activeIndex"
      class="el-menu"
      mode="horizontal"
      :ellipsis="false"
      @select="handleSelect"
    >
      <!-- <el-menu-item class="logobtn"> -->
      <div class="logobtn">
        <img
          class="imgw145"
          style="width: 75%"
          :src="require('@/assets/img/logo.png')"
        />
      </div>

      <!-- </el-menu-item> -->
      <div class="flex-grow" />
      <el-menu-item index="1"> 首页 </el-menu-item>
      <el-menu-item index="2"> 游戏 </el-menu-item>
      <el-menu-item index="3">新闻中心</el-menu-item>
      <el-menu-item index="4">关于公司</el-menu-item>
      <el-menu-item index="5">加入盛和</el-menu-item>
      <el-menu-item index="6">联系我们</el-menu-item>
    </el-menu>
  </div>
  <!-- H5 顶部 -->
  <div class="h5-top h5">
    <img class="top-icon" :src="require('@/assets/img/logo.png')" />
    <img
      v-if="!menuShow"
      class="top-more"
      @click="handleShowMenu"
      :src="require('@/assets/imgh5/more.png')"
    />
    <img
      v-else
      class="top-more"
      @click="handleCloseMenu"
      :src="require('@/assets/imgh5/close.png')"
    />

    <!-- h5菜单 -->
    <div
      class="menu-zhe"
      v-if="menuShow"
      @click="
        () => {
          menuShow = false
        }
      "
    >
      <div class="h5-menu">
        <div
          class="menu-li"
          :class="activeIndex == '1' ? 'active-li' : ''"
          @click.stop="handleSelect('1')"
        >
          首页
          <img
            v-if="activeIndex == '1'"
            class="menu-index"
            :src="require('@/assets/imgh5/menuindex.png')"
          />
        </div>
        <div
          class="menu-li"
          style="top: 1.48rem"
          :class="activeIndex == '2' ? 'active-li' : ''"
          @click.stop="handleSelect('2')"
        >
          游戏
          <img
            v-if="activeIndex == '2'"
            class="menu-index"
            :src="require('@/assets/imgh5/menuindex.png')"
          />
        </div>
        <div
          class="menu-li"
          style="top: 2.96rem"
          @click.stop="handleSelect('3')"
          :class="activeIndex == '3' ? 'active-li' : ''"
        >
          新闻中心
          <img
            v-if="activeIndex == '3'"
            class="menu-index"
            :src="require('@/assets/imgh5/menuindex.png')"
          />
        </div>
        <div
          class="menu-li"
          style="top: 4.44rem"
          @click.stop="handleSelect('4')"
          :class="activeIndex == '4' ? 'active-li' : ''"
        >
          关于公司
          <img
            v-if="activeIndex == '4'"
            class="menu-index"
            :src="require('@/assets/imgh5/menuindex.png')"
          />
        </div>
        <div
          class="menu-li"
          style="top: 5.91rem"
          @click.stop="handleSelect('5')"
          :class="activeIndex == '5' ? 'active-li' : ''"
        >
          加入盛和
          <img
            v-if="activeIndex == '5'"
            class="menu-index"
            :src="require('@/assets/imgh5/menuindex.png')"
          />
        </div>
        <div
          class="menu-li"
          style="top: 7.38rem"
          @click.stop="handleSelect('6')"
          :class="activeIndex == '6' ? 'active-li' : ''"
        >
          联系我们
          <img
            v-if="activeIndex == '6'"
            class="menu-index"
            :src="require('@/assets/imgh5/menuindex.png')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
const store = useStore()
const activeIndex = ref(store.state.activeIndex || '1')
const router = useRouter()
const menuShow = ref(false)
const href = computed(() => store.state.href)
const activeIndex1 = computed(() => store.state.activeIndex)
const ifVideo = computed(() => store.state.ifVideo)
watch(activeIndex1, (newV) => {
  activeIndex.value = newV
})
const handleShowMenu = () => {
  menuShow.value = true
}
const handleCloseMenu = () => {
  menuShow.value = false
}
// 菜单点击
const handleSelect = (key) => {
  menuShow.value = false
  store.commit('setActiveIndex', key)
  sessionStorage.setItem('active', key)
  if (key == '1') {
    if (href.value === '/') return
    router.push({ path: '/', query: { page: 1 } })
  } else if (key == '2') {
    if (href.value === '/GameList') return
    router.push({ path: '/GameList' })
  } else if (key == '3') {
    if (href.value === '/news') return
    router.push({ path: '/news' })
  } else if (key == '4') {
    if (href.value === '/auto') return
    router.push({ path: '/auto', query: { page: 1 } })
  } else if (key == '5') {
    if (href.value === '/join') return
    router.push({ path: '/join' })
  } else if (key == '6') {
    if (href.value === '/contact') return
    router.push({ path: '/contact' })
  }
}
</script>

<style scoped>
.show {
  transition: 0.3s;
}
.show-down {
  transform: translateY(-75px);
  box-shadow: none;
}
.show-up {
  transform: translateY(0);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}
.logobtn {
  display: flex;
  align-items: center;
  width: 174px;
}
.imgw145 {
  display: flex;
  align-items: center;
}
.menu-nav {
  position: absolute;
  top: 70px;
  left: 0px;
}
.menu-bug {
  width: 100%;
}
.about-menu .menu-nav {
  display: none;
}
.about-menu:hover .menu-nav {
  display: block;
}
.nav-ul {
  position: absolute;
  left: 0;
  top: 10%;
  width: 100%;
  height: 90%;
}
.nav-li {
  height: 33%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.nav-li:hover {
  color: #cc2908;
}
.menu-index {
  position: absolute;
  width: 16px;
  top: 72px;
  left: calc(50% - 8px);
}
.affix-container {
  background: url('../../src/assets/img/topline.png');
  height: 75px;
}
.el-menu {
  width: 80%;
  margin: 0 auto;
  background-color: initial;
  border-bottom: none;
  height: 75px;
}
.flex-grow {
  flex-grow: 1;
}
/deep/.el-menu--horizontal > .el-menu-item {
  border-bottom: none;
}
/deep/ .el-menu--horizontal > .el-menu-item.is-active {
  color: #cc2908 !important;
}
.el-menu--horizontal > .el-menu-item {
  color: #474747;
  font-size: 18px;
}
.el-menu--horizontal > .el-sub-menu .el-sub-menu__title {
  color: #474747 !important;
}
/deep/ .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid #cc2908;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  background-color: inherit;
  color: #cc2908;
  border-bottom: 2px solid #cc2908;
}
.el-menu--horizontal > .el-sub-menu .el-sub-menu__title:hover {
  background-color: inherit;
  color: #cc2908;
  border-bottom: 2px solid #cc2908;
}
.m-1 {
  margin-top: 30px;
  width: 140px;
}
@media screen and (max-width: 750px) {
  .h5-top {
    width: 7.5rem;
    height: 1.3rem;
    background: url('../../src/assets/imgh5/topline.png');
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.4rem;
    box-sizing: border-box;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  }
  .top-icon {
    width: 2rem;
  }
  .top-more {
    width: 0.6rem;
  }
  .menu-zhe {
    position: fixed;
    top: 1.3rem;
    left: 0;
    width: 7.5rem;
    height: calc(100% - 1.3rem);
    background: #0000005e;
    z-index: 999;
  }
  .h5-menu {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 8.82rem;
    background: #fff;
  }
  .h5-m-bg {
    width: 3rem;
  }
  .menu-li {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1.47rem;
    line-height: 1.47rem;
    text-align: center;
    color: #474747;
    font-size: 18px;
  }
  .menu-index {
    position: absolute;
    width: 2rem;
    top: 1rem;
    left: 2.75rem;
  }
  .active-li {
    color: #cc2908;
  }
}
</style>
