<template>
  <div class="home" id="myElement">
    <div class="section section1">
      <img class="homebg1 pc" :src="require('@/assets/img/newsbg1.jpg')" />
      <img class="homebg1 h5" :src="require('@/assets/imgh5/newsbg1.jpg')" />
      <img class="div2" :src="require('@/assets/img/div2.png')" />
      <img class="div3" :src="require('@/assets/img/div3.png')" />

      <img class="h3dimg" :src="require('@/assets/img/zptitle.png')" />
    </div>

    <div class="section section4">
      <!-- 搜索框 -->
      <div class="input-item">
        <img class="s-icon" :src="require('@/assets/img/search.png')" />
        <input
          type="text"
          class="s-input"
          v-model="searchText"
          placeholder="搜索岗位"
        />
        <div class="sl-btn" @click="getJoinList(false)">搜索</div>
      </div>
      <!-- 筛选框 -->
      <div class="search-type">
        <div class="st-line">
          <div class="st-title">工作地点</div>
          <div class="st-value">
            <div
              class="stv-li"
              @click="setSearchOption(3, '')"
              :class="searchAdress == '' ? 'active-li' : ''"
            >
              不限
            </div>
            <div
              :class="searchAdress == item ? 'active-li' : ''"
              class="stv-li"
              @click="setSearchOption(3, item)"
              v-for="(item, index) in adressList"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="st-line">
          <div class="st-title">工作性质</div>
          <div class="st-value">
            <div
              class="stv-li"
              :class="searchStyle == 0 ? 'active-li' : ''"
              @click="setSearchOption(2, 0)"
            >
              不限
            </div>
            <div
              class="stv-li"
              :class="searchStyle == '正式' ? 'active-li' : ''"
              @click="setSearchOption(2, '正式')"
            >
              正式
            </div>
            <div
              class="stv-li"
              :class="searchStyle == '实习' ? 'active-li' : ''"
              @click="setSearchOption(2, '实习')"
            >
              实习
            </div>
          </div>
        </div>
        <div class="st-line">
          <div class="st-title">职业类型</div>
          <div class="st-value">
            <div
              class="stv-li"
              @click="setSearchOption(1, 0)"
              :class="searchType == 0 ? 'active-li' : ''"
            >
              不限
            </div>
            <div
              class="stv-li"
              :class="searchType == item.job_type ? 'active-li' : ''"
              v-for="(item, index) in typeList"
              :key="index"
              @click="setSearchOption(1, item.job_type)"
            >
              {{ item.job_type }}
            </div>
          </div>
        </div>
      </div>
      <!-- 列表 -->
      <!-- 职位列表 -->
      <div class="job-item">
        <!-- 表头 -->
        <div class="t-header">
          <div class="t-item">职位名称</div>
          <div class="t-item">职位类型</div>
          <div class="t-item">工作地点</div>
          <div class="t-item">工作性质</div>
        </div>
        <!-- 表内容 -->
        <div class="tip-text" v-if="!tableList.length || tableList.length == 0">
          这里暂时还没有内容噢~
        </div>
        <div v-else class="t-body-all" id="tablebody">
          <div
            class="t-body"
            v-for="(item, index) in showList"
            :key="index"
            @click="hanleGoDetail(item)"
          >
            <div class="t-item">{{ item.job_name }}</div>
            <div class="t-item">{{ item.job_type }}</div>
            <div class="t-item">{{ item.job_address }}</div>
            <div class="t-item">{{ item.job_attr }}</div>
          </div>
        </div>
      </div>

      <div class="more-b" v-if="totalPage > 1">
        <div class="more-btn" v-if="page < totalPage" @click="getMore">
          查看更多
        </div>
        <div class="nomore" v-if="page >= totalPage && totalPage > 1">
          没有更多了~
        </div>
      </div>
    </div>
  </div>

  <div class="detail-contain" v-if="showDiv == 2">
    <div class="top-w">
      <div class="top-close">
        <img
          class="close-icon"
          @click="handleBack"
          :src="require('@/assets/img/zpclose.png')"
        />
      </div>
      <div class="name-div">
        <div class="join-name">{{ detail.job_name }}</div>
        <div class="join-detail">
          <span>{{ detail.job_address }}</span
          ><span class="space">|</span> <span>{{ detail.job_type }}</span
          ><span class="space">|</span>
          <span>{{ detail.job_attr }}</span>
        </div>
      </div>
      <div class="detail-text">
        <div class="detail-title">工作职责</div>
        <div class="detail-font" v-html="detail.job_desc"></div>
        <div class="detail-title">任职要求</div>
        <div class="detail-font" v-html="detail.job_request"></div>
      </div>
    </div>
    <div class="bottom-h">
      <div class="bhline">
        请应聘者将简历以附件形式发送:<span style="color: rgb(47 98 233)">{{
          detail.job_tel
        }}</span
        >，邮件主题:姓名+应聘职位。
      </div>
      <div class="bhline">美术类职位请在简历中附相应作品，谢谢！</div>
      <div class="bhline">招聘联系电话:0571-88841200</div>
    </div>
  </div>
</template>
    
  <script setup>
import { ref, onMounted, onUnmounted, getCurrentInstance } from "vue";
const { proxy } = getCurrentInstance();
const adressList = ref([]);
const typeList = ref([]);
const searchText = ref("");
const searchType = ref(0);
const searchStyle = ref(0);
const searchAdress = ref("");
const tableList = ref([]);
// 分页
const showList = ref([]);
const page = ref(1);
const totalPage = ref(1);
// 弹窗
const showDiv = ref(1);
const detail = ref({});
// 初始化数据
const webId = ref();
// 初始化
const initData = async () => {
  return new Promise((reslove) => {
    let params = {
      host: window.location.host, //"www.sh.com",
    };
    proxy.$axios.post("/Index/initData", params).then(async (res) => {
      if (res.code == 200) {
        webId.value = res.data.company_id;
        getTypeList();
        getAddress();
        getJoinList(true);
        reslove();
      }
    });
  });
};
// 展示哪些
const getShowList = (n) => {
  for (let i = 0; i < n; i++) {
    if (tableList.value[(page.value - 1) * n + i]) {
      showList.value.push(tableList.value[(page.value - 1) * n + i]);
    }
  }
};
// 获取招聘类型
const getTypeList = () => {
  proxy.$axios.post("/User/getJobTypes").then(async (res) => {
    if (res.code == 200) {
      typeList.value = res.data;
    }
  });
};
// 获取地址列表
const getAddress = () => {
  let params = {
    company_id: webId.value,
  };
  proxy.$axios.post("/User/getJobAddress", params).then(async (res) => {
    if (res.code == 200) {
      adressList.value = res.data;
    }
  });
};
// 获取招聘列表
const getJoinList = (val) => {
  let params = {
    job_type: searchType.value,
    job_attr: searchStyle.value,
    company_id: webId.value,
    job_name: searchText.value,
    job_address: searchAdress.value,
  };
  proxy.$axios.post("/Index/getJobs", params).then(async (res) => {
    if (res.code == 200) {
      tableList.value = res.data;
      if (!val) {
        showList.value = [];
      }
      totalPage.value = Math.ceil(tableList.value.length / 10);
      getShowList(10);
    }
  });
};
// 查看更多游戏
const getMore = () => {
  page.value = page.value + 1;
  getShowList(10);
};
// 下拉赋值
const setSearchOption = (type, val) => {
  if (type == 1) {
    searchType.value = val;
  } else if (type == 2) {
    searchStyle.value = val;
  } else if (type == 3) {
    searchAdress.value = val;
  }
  getJoinList(false);
};
// 查看详情
const hanleGoDetail = (item) => {
  detail.value = item;
  showDiv.value = 2;
};
const handleBack = () => {
  showDiv.value = 1;
};
onMounted(async () => {
  await initData();
});

onUnmounted(() => {});
</script>
  <style src="./join.css" scoped></style>
  <style>
.el-input__wrapper.is-focus {
  box-shadow: 0 0 0 1px #cc2908 inset;
}
.el-month-table td.today .cell {
  color: #cc2908;
}
</style>
    