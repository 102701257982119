<template>
  <div class="home" id="myElement">
    <div class="section section1">
      <img class="homebg1 pc" :src="require('@/assets/img/newsbg1.jpg')" />
      <img class="homebg1 h5" :src="require('@/assets/imgh5/newsbg1.jpg')" />
      <img class="div2" :src="require('@/assets/img/div2.png')" />
      <img class="div3" :src="require('@/assets/img/div3.png')" />

      <img class="h3dimg" :src="require('@/assets/img/cuicon.png')" />
    </div>

    <div class="section section2">
      <img class="homebg2 pc" :src="require('@/assets/img/cubg2.jpg')" />
      <img class="homebg2 h5" :src="require('@/assets/imgh5/cubg2.jpg')" />
      <img class="bc1 titleicon" :src="require('@/assets/img/cutitle.png')" />
      <div class="center active">
        <img class="logo-c" :src="require('@/assets/img/cshlogo.png')" />
        <div class="c-area">
          <img class="cubg" :src="require('@/assets/img/cutextbg.png')" />

          <div class="font-contain">
            <div>公司电话：0571-88841200</div>
            <div class="f-c">邮政编码：311200</div>
            <div>公司地址：杭州市萧山区保亿中心B幢39楼</div>
          </div>
        </div>
      </div>
      <div class="center c-ul">
        <div class="c-li">
          <img class="liimg" :src="require('@/assets/img/cu1.png')" />
          <div class="font-tip">代理授权</div>

          <!-- 浮出部分 -->
          <div class="float-li">
            <img class="liimg" :src="require('@/assets/img/cuhover.png')" />
            <div class="float-contain">
              <div class="f-title">代理授权</div>
              <div class="f-detail">
                <div class="detail-li">
                  <img class="c-icon" :src="require('@/assets/img/user.png')" />
                  <div>联系人：梁先生</div>
                </div>
                <div class="detail-li">
                  <img class="c-icon" :src="require('@/assets/img/qq.png')" />
                  <div>QQ：105654409</div>
                </div>
                <div class="line"></div>
                <div class="detail-li">
                  <img class="c-icon" :src="require('@/assets/img/user.png')" />
                  <div>联系人：宋先生</div>
                </div>
                <div class="detail-li">
                  <img class="c-icon" :src="require('@/assets/img/qq.png')" />
                  <div>QQ：465099951</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-li">
          <img class="liimg" :src="require('@/assets/img/cu2.png')" />
          <div class="font-tip">海外合作</div>

          <!-- 浮出部分 -->
          <div class="float-li">
            <img class="liimg" :src="require('@/assets/img/cuhover.png')" />
            <div class="float-contain">
              <div class="f-title">海外合作</div>
              <div class="f-detail">
                <div class="detail-li">
                  <img class="c-icon" :src="require('@/assets/img/user.png')" />
                  <div>联系人：李女士</div>
                </div>
                <div class="detail-li">
                  <img class="c-icon" :src="require('@/assets/img/qq.png')" />
                  <div>QQ：1031232282</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-li">
          <img class="liimg" :src="require('@/assets/img/cu3.png')" />
          <div class="font-tip">市场合作</div>

          <!-- 浮出部分 -->
          <div class="float-li">
            <img class="liimg" :src="require('@/assets/img/cuhover.png')" />
            <div class="float-contain">
              <div class="f-title">市场合作</div>
              <div class="f-detail">
                <div class="detail-li">
                  <img class="c-icon" :src="require('@/assets/img/user.png')" />
                  <div>联系人：徐女士</div>
                </div>
                <div class="detail-li">
                  <img class="c-icon" :src="require('@/assets/img/qq.png')" />
                  <div>QQ：836670093</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-li">
          <img class="liimg" :src="require('@/assets/img/cu4.png')" />
          <div class="font-tip">商务合作</div>

          <!-- 浮出部分 -->
          <div class="float-li">
            <img class="liimg" :src="require('@/assets/img/cuhover.png')" />
            <div class="float-contain">
              <div class="f-title">商务合作</div>
              <div class="f-detail">
                <div class="detail-li">
                  <img class="c-icon" :src="require('@/assets/img/user.png')" />
                  <div>手游商务卢女士</div>
                </div>
                <div class="detail-li">
                  <img class="c-icon" :src="require('@/assets/img/qq.png')" />
                  <div>QQ：3414234589</div>
                </div>
                <div class="line"></div>
                <div class="detail-li">
                  <img class="c-icon" :src="require('@/assets/img/user.png')" />
                  <div>页游商务徐女士</div>
                </div>
                <div class="detail-li">
                  <img class="c-icon" :src="require('@/assets/img/qq.png')" />
                  <div>QQ：578114945</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
  <script setup>
import { onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
const store = useStore();
store.commit("setLoad", true);
onMounted(async () => {
  store.commit("setLoad", false);
});

onUnmounted(() => {});
</script>
<style src="./contact.css" scoped></style>
<style>
</style>
    