<template>
  <div class="home" id="myElement">
    <div class="section section1" id="section1">
      <img class="homebg1 pc" :src="require('@/assets/img/newsbg1.jpg')" />
      <img class="homebg1 h5" :src="require('@/assets/imgh5/newsbg1.jpg')" />
      <img class="div2" :src="require('@/assets/img/div2.png')" />
      <img class="div3" :src="require('@/assets/img/div3.png')" />

      <img class="h3dimg" :src="require('@/assets/img/jointitle.png')" />
    </div>

    <div
      class="section section2"
      :class="styleKey2 == 1 ? 'ac-section2' : ''"
      id="section2"
    >
      <img class="homebg2 pc" :src="require('@/assets/img/joinbg2.jpg')" />
      <img class="homebg2 h5" :src="require('@/assets/imgh5/joinbg2.jpg')" />
      <div class="btn sh-btn" @click="handleGoList">社会招聘</div>

      <div class="bc2" :key="styleKey2">
        <img class="lnbg pc" :src="require('@/assets/img/lnbg2.png')" />
        <div class="lntext">
          <div>
            快速成长中的盛和,非常重视对于员工的培训工作,通过关注员工纵深发展和关注知识经验分享的<span
              style="color: #cc2908"
              >“一纵一横”</span
            >两条主线,搭建了一个系统的科学的学习平台,全方位的为员工提供包括岗位资格认证、专家讲坛、海外培训等各种形式的学习。
          </div>
        </div>
      </div>
      <img
        class="bc1 titleicon"
        :key="styleKey2"
        :src="require('@/assets/img/lnicon2.png')"
      />

      <div class="qa-div" :key="styleKey2">
        <img class="lnbg pc" :src="require('@/assets/img/qabg.png')" />
        <img class="lnbg h5" :src="require('@/assets/imgh5/qabg.png')" />
        <div class="qaitem">
          <div class="qa-li">
            很多人都会在面试时问：<span style="color: #cc2908"
              >“公司会给我什么样的培训机会”？</span
            >
          </div>
          <div class="qa-li">
            很多人也会在刚刚进入公司之后感言：<span style="color: #cc2908"
              >“这里的办公环境就像是一个学校”！</span
            >
          </div>
          <div class="qa-li">
            所有人的都会在加入盛和一段时间之后告诉身边的人：<span
              style="color: #cc2908"
              >“这里就是一个真正的学校”！</span
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="section section3"
      :class="styleKey3 == 1 ? 'ac-section3' : ''"
      id="section3"
      :key="styleKey3"
    >
      <img class="homebg2 pc" :src="require('@/assets/img/joinbg3.jpg')" />
      <img class="homebg2 h5" :src="require('@/assets/imgh5/joinbg3.jpg')" />
      <img
        class="valueicon titleicon"
        :src="require('@/assets/img/valueicon.png')"
      />
      <!-- pc -->
      <van-swipe
        class="swipe pc"
        ref="swipe"
        :autoplay="false"
        :show-indicators="false"
        lazyRender
      >
        <van-swipe-item>
          <img
            class="valueimg leftimg"
            :src="require(`@/assets/img/value1.png`)"
          />
          <img class="valueimg" :src="require(`@/assets/img/value2.png`)" />
        </van-swipe-item>
        <van-swipe-item>
          <img
            class="valueimg leftimg"
            :src="require(`@/assets/img/value3.png`)"
          />
          <img class="valueimg" :src="require(`@/assets/img/value4.png`)" />
        </van-swipe-item>
        <van-swipe-item>
          <img
            class="valueimg leftimg"
            :src="require(`@/assets/img/value5.png`)"
          />
          <img class="valueimg" :src="require(`@/assets/img/value6.png`)" />
        </van-swipe-item>
      </van-swipe>
      <!-- 切换按钮 -->
      <div class="swiper-btn pc">
        <img
          class="s-btn prev"
          :src="require('@/assets/img/t-left.png')"
          @click="bannerPrev"
        />
        <img
          class="s-btn next"
          :src="require('@/assets/img/t-right.png')"
          @click="bannerNext"
        />
      </div>
      <!-- h5  -->
      <div class="h5 value-ul">
        <img class="valueimg" :src="require(`@/assets/img/value1.png`)" />
        <img class="valueimg" :src="require(`@/assets/img/value2.png`)" />
        <img class="valueimg" :src="require(`@/assets/img/value3.png`)" />
        <img class="valueimg" :src="require(`@/assets/img/value4.png`)" />
        <img class="valueimg" :src="require(`@/assets/img/value5.png`)" />
        <img class="valueimg" :src="require(`@/assets/img/value6.png`)" />
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
const store = useStore();
store.commit("setLoad", true);
const styleKey2 = ref(0);
const styleKey3 = ref(0);
// 滑动
const canRun = ref(true);
const swipe = ref();
const router = useRouter();
// 判断是否移动端
const ifH5 = () => {
  if (
    navigator.userAgent.match(/Mobi/i) ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPhone/i)
  ) {
    return true;
  } else {
    return false;
  }
};
// 轮播
const bannerPrev = () => {
  swipe.value.prev();
};
const bannerNext = () => {
  swipe.value.next();
};
// 社会招聘按钮点击
const handleGoList = () => {
  router.push("joinList");
};
// 鼠标滑动监测
const handleWheel = (e) => {
  if (ifH5()) return;
  if (canRun.value) {
    canRun.value = false;
    goScroll(e);
    setTimeout(() => {
      canRun.value = true;
    }, 100);
  }
};
const goScroll = (e) => {
  //e.wheelDelta 用来判断上一个下一个 <0 下一个 >0上一个
  if (e.wheelDelta < 0) {
    next();
  }
};
// 下一个
const next = () => {
  if (
    window.visualViewport.pageTop >=
    document.getElementById(`section1`).offsetHeight * 0.1
  ) {
    if (styleKey2.value == 0) {
      styleKey2.value++;
    }
  }
  if (
    window.visualViewport.pageTop >=
    document.getElementById(`section1`).offsetHeight +
      document.getElementById(`section2`).offsetHeight * 0.1
  ) {
    if (styleKey3.value > 0) return;
    styleKey3.value++;
  }
};
onMounted(async () => {
  window.addEventListener("wheel", handleWheel, { passive: false });
  store.commit("setLoad", false);
});

onUnmounted(() => {
  window.removeEventListener("wheel", handleWheel);
});
</script>
<style src="./join.css" scoped></style>
<style>
.el-input__wrapper.is-focus {
  box-shadow: 0 0 0 1px #cc2908 inset;
}
.el-month-table td.today .cell {
  color: #cc2908;
}
</style>
  