/**
 * @descripttion: 转换时间格式 yyyy-MM-dd,或者yyyy/MM/dd
 * @param {*} timestamp 时间戳
 * @param {*} separator 分隔符("-"或者"/"),默认是"-"
 * @return {*}
 */

export function dateFormat(timestamp, separator = '-') {
  if (timestamp) {
    const time = new Date(parseInt(timestamp));
    const year = time.getFullYear();
    let month = time.getMonth() + 1;
    let date = time.getDate();
    month = padLeftZero(month, 2);
    date = padLeftZero(date, 2);
    return year + separator + month + separator + date;
  }
  return timestamp;
}

/**
 * @descripttion: 转换时间格式 yyyy-MM-dd HH:mm:ss,或者yyyy/MM/dd HH:mm:ss
 * @param {*} timestamp 时间戳
 * @param {*} separator 分隔符("-"或者"/"),默认是"-"
 * @return {*}
 */
export function dateFormatToSecond(timestamp, separator = '-') {
  if (timestamp) {
    const time = new Date(parseInt(timestamp));
    const year = time.getFullYear();
    let month = time.getMonth() + 1;
    let date = time.getDate();
    let hours = time.getHours();
    let minute = time.getMinutes();
    let second = time.getSeconds();

    month = padLeftZero(month, 2);
    date = padLeftZero(date, 2);
    hours = padLeftZero(hours, 2);
    minute = padLeftZero(minute, 2);
    second = padLeftZero(second, 2);

    return (
      year +
      separator +
      month +
      separator +
      date +
      ' ' +
      hours +
      ':' +
      minute +
      ':' +
      second
    );
  }
  return timestamp;
}

/**
 * @descripttion: 转换时间格式 yyyy-MM-dd HH:mm,或者yyyy/MM/dd HH:mm
 * @param {*} timestamp 时间戳
 * @param {*} separator 分隔符("-"或者"/"),默认是"-"
 * @return {*}
 */
export function dateFormatToMinute(timestamp, separator = '-') {
  if (timestamp) {
    const time = new Date(parseInt(timestamp));
    const year = time.getFullYear();
    let month = time.getMonth() + 1;
    let date = time.getDate();
    let hours = time.getHours();
    let minute = time.getMinutes();

    month = padLeftZero(month, 2);
    date = padLeftZero(date, 2);
    hours = padLeftZero(hours, 2);
    minute = padLeftZero(minute, 2);

    return year + separator + month + separator + date + ' ' + hours + ':' + minute;
  }
  return timestamp;
}

/**
 * @descripttion: 左侧补全0
 * @param {*} str 需要转换的字符串
 * @param {*} len 转化后字符的长度
 * @return {*} 转化后的字符
 */
export function padLeftZero(str, len) {
  str = str.toString();
  return str.padStart(len, '0');
}

/**
 * @descripttion: 获取当天24小时时间戳(23时59分59秒)
 * @param {*}
 * @return {*} 返回开始时间戳和结束时间戳
 */
export function todayTimestamp() {
  const now = new Date();
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);
  const startTime = now.getTime();
  const endTime = now.getTime() + 24 * 60 * 60 * 1000 - 1000;
  return { startTime, endTime };
}

/**
 * @descripttion: 获取本周(23时59分59秒)
 * @param {*}
 * @return {*} 返回开始时间戳和结束时间戳
 */
export function weekTimestamp() {
  const nowTime = new Date();
  const day = nowTime.getDay();
  nowTime.setHours(0);
  nowTime.setMinutes(0);
  nowTime.setSeconds(0);
  nowTime.setMilliseconds(0);
  const startTime = nowTime.getTime() - (day - 1) * 86400000;
  const endTime = nowTime.getTime() + (8 - day) * 86400000 - 1000;
  return { startTime, endTime };
}

/**
 * @descripttion: 获取本月(23时59分59秒)
 * @param {*}
 * @return {*} 返回开始时间戳和结束时间戳
 */
export function monthTimestamp() {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const days = new Date(year, month, 0).getDate();
  now.setDate(1);
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);
  const startTime = now.getTime();
  const endTime = now.getTime() + 3600 * 1000 * 24 * days - 1000;
  return { startTime, endTime };
}

/**
 * @descripttion: 判断是否是闰年
 * @param {*}
 * @return {*} boolean
 */
export function isLeapYear(year) {
  return year % 400 == 0 || (year % 4 == 0 && year % 100 != 0);
}

/**
 * @descripttion: 获取某年某月天数
 * @param {*}
 * @return {*} 数组，每一个元素是对应月份的天数
 */
export function getMonthDays(year, month) {
  return [31, isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][
    month - 1
  ];
}

/**
 * @descripttion: 根据日期判断是一年的第几周
 * @param {*}
 * @return {*}
 */

export function getWeek(date) {
  if (date) {
    const targetDay = new Date(date);
    const y = targetDay.getFullYear();
    const m = targetDay.getMonth();
    let d = targetDay.getDate();
    for (let i = 1; i < m + 1; i++) {
      d = d + getMonthDays(y, i);
    }
    const yearFirstDay = new Date(y, 0, 1).getDay();

    d = d + yearFirstDay;
    const week = Math.ceil(d / 7);
    return week - 1;
  }
}

/**
 * @descripttion: 获取最近7天(23时59分59秒)
 * @param {*}
 * @return {*} 返回开始时间戳和结束时间戳
 */
export function sevenTimestamp() {
  const nowTime = new Date(new Date().toLocaleDateString()).getTime()+24*60*60*1000-1;
  const startTime = nowTime - 7*24*60*60*1000+1;
  const endTime = nowTime;
  return { startTime, endTime };
}