import { createRouter, createWebHashHistory } from 'vue-router'
import  useStore  from "@/store/index.js";

import Home from '@/views/Home/home'
import auto from '@/views/auto/auto'
import Gamelist from '@/views/Game/Gamelist'
import join from '@/views/join/join'
import joinList from '@/views/join/joinList'
import news from '@/views/news/news'
import newsDetail from '@/views/news/newsDetail'
import contact from '@/views/contact/contact'



//路由数组
const routes = [
    {
        //基本格式
        path: "/",
        name: "Home",
        component: Home,
        meta:{
            title:'首页'
        }
    },
    {
        //基本格式
        path: "/auto",
        name: "Auto",
        component: auto,
        meta:{
            title:'关于我们'
        }
    },
    {
        //基本格式
        path: "/Gamelist",
        name: "Gamelist",
        component: Gamelist,
    },
    {
        path:"/join",
        name: "join",
        component: join,
    },
    {
        path:"/news",
        name: "news",
        component: news,
    },
    {
        path:"/newsDetail",
        name: "newsDetail",
        component: newsDetail,
    },
    {
        path:"/joinList",
        name: "joinList",
        component: joinList,
    },
    {
        path:"/contact",
        name: "contact",
        component: contact,
    },
]
//路由对象
const router = createRouter({
    mode: 'hash',
    history: createWebHashHistory(),
    routes //上面的路由数组
})
const store = useStore
router.beforeEach((to, from, next) => {
    store.commit('setHref',to.path)
    if(to.path=='/'){
        store.commit("setActiveIndex", 1);
    }
    next(); // 继续导航
})
router.afterEach((to, from) => {
    if(to.path === from.path) return
    window.scrollTo(0, 0); // 每次路由切换后滚动到顶部
})


//导出路由对象，在main.js中引用
export default router
