<template>
  <div class="auto_css" id="myElement">
    <div class="section1 section" id="section1">
      <img
        class="pc"
        :src="require('@/assets/img/glbanner.jpg')"
        style="width: 100%; opacity: 0"
      />
      <img
        class="h5"
        :src="require('@/assets/imgh5/glbanner.png')"
        style="width: 100%; opacity: 0; margin-top: 1.3rem"
      />
      <van-swipe
        class="swipe"
        ref="swipe"
        :autoplay="false"
        :show-indicators="false"
        @change="onChange"
        lazyRender
      >
        <van-swipe-item
          v-for="(item, index) in ifH5() ? h5bannerList : bannerList"
          :key="index"
        >
          <!-- pc -->
          <img v-if="!ifH5()" :src="item.game_banner3" style="width: 100%" />
          <!-- h5 -->
          <img v-else :src="item.game_banner3_h5" style="width: 100%" />
        </van-swipe-item>
      </van-swipe>
      <!-- 切换按钮 -->
      <div class="swiper-btn">
        <img
          class="s-btn prev"
          :src="require('@/assets/img/b-left.png')"
          @click="bannerPrev"
        />
        <div class="index-font pc">
          {{ indexImg + 1 }}/{{
            ifH5() ? h5bannerList.length : bannerList.length
          }}
        </div>
        <img
          class="s-btn next"
          :src="require('@/assets/img/b-right.png')"
          @click="bannerNext"
        />
      </div>
      <!-- pc右边游戏详情 -->
      <!-- <div class="right-game pc" :key="styleKey">
        <img :src="require('@/assets/img/gl-left.png')" class="gl-right" />
        <div class="game-detail">
          <img
            class="gl-icon"
            :src="bannerList[indexImg] ? bannerList[indexImg].game_icon : ''"
          />
          <div class="detail">
            <div class="t-name">
              {{ bannerList[indexImg] ? bannerList[indexImg].game_name : "" }}
            </div>
            <div class="t-desc">
              {{ bannerList[indexImg] ? bannerList[indexImg].game_desc : "" }}
            </div>
          </div>
        </div>
        <img
          class="gl-right-doc"
          :src="require('@/assets/img/gl-left-doc.png')"
        />
      </div> -->
      <!-- h5底部游戏 -->
      <!-- <div class="bottom-game h5" :key="styleKey">
        <div class="bg-left">
          <img
            class="gl-icon"
            :src="
              h5bannerList[indexImg] ? h5bannerList[indexImg].game_icon : ''
            "
          />
          <div class="detail">
            <div class="t-name">
              {{
                h5bannerList[indexImg] ? h5bannerList[indexImg].game_name : ""
              }}
            </div>
            <div class="t-desc">
              {{
                h5bannerList[indexImg] ? h5bannerList[indexImg].game_desc : ""
              }}
            </div>
          </div>
        </div>
        <div
          class="btn g-btn"
          v-if="h5bannerList[indexImg] && h5bannerList[indexImg].game_url"
        >
          进入官网
        </div>
      </div> -->
    </div>
    <div class="section2 section" id="section2">
      <img class="glg-icon" :src="require('@/assets/img/glgameicon.png')" />
      <div class="contain" :key="styleKey2">
        <div
          v-for="(item, index) in showGameList"
          :key="index"
          :class="styleKey2 == 1 ? 'a-move' : ''"
          class="game-item"
        >
          <img class="glbg" :src="item.game_banner2" />
          <div class="gname">{{ item.game_name }}</div>

          <!-- 悬浮出现 -->
          <div class="game-block">
            <div class="float-item">
              <img class="glicon" :src="item.game_icon" />
              <div class="desc">
                {{ item.game_desc }}
              </div>
              <div
                class="fl-bottom"
                v-if="item.game_url"
                @click="handleGoOffice(item)"
              >
                <div>进入官网</div>
                <img
                  class="gright"
                  :src="require('@/assets/img/g-right.png')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="more-b">
        <div class="more-btn" v-if="page < totalPage" @click="getMore">
          查看更多
        </div>
        <div class="nomore" v-if="page >= totalPage && totalPage > 1">
          没有更多了~
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted, onUnmounted } from "vue";
// import { dateFormat } from "@/utils/dateFormat";
import { useStore } from "vuex";
const store = useStore();
store.commit("setLoad", true);
const { proxy } = getCurrentInstance();
const styleKey2 = ref(0);
// 轮播
const swipeTimer = ref();
const indexImg = ref(0);
const swipe = ref(null);
const autoplay = ref(5000);
const bannerList = ref([]);
const h5bannerList = ref([]);
const styleKey = ref(0);
// pc展示的游戏列表4个
const gameList = ref([]);
const showGameList = ref([]);
const page = ref(1);
const totalPage = ref(1);
const webId = ref();
// 滑动
const canRun = ref(true);
// 判断是否移动端
const ifH5 = () => {
  if (
    navigator.userAgent.match(/Mobi/i) ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPhone/i)
  ) {
    return true;
  } else {
    return false;
  }
};
// 展示哪些游戏
const getShowList = (n) => {
  for (let i = 0; i < n; i++) {
    if (gameList.value[(page.value - 1) * n + i]) {
      showGameList.value.push(gameList.value[(page.value - 1) * n + i]);
    }
  }
};
// 初始化
const initData = async () => {
  let params = {
    host: window.location.host, //"www.sh.com"
  };
  proxy.$axios.post("/Index/initData", params).then(async (res) => {
    if (res.code == 200) {
      webId.value = res.data.company_id;
      getBanner();
      getGame();
    }
  });
};
// 获取轮播
const getBanner = () => {
  let params = {
    company_id: webId.value,
  };
  proxy.$axios.post("/User/getGameCarousel", params).then((res) => {
    if (res.code == 200) {
      h5bannerList.value = res.data.game_h5;
      bannerList.value = res.data.game_pc;
    }
  });
};
// 获取游戏
const getGame = async () => {
  let params = {
    company_id: webId.value,
    type: "list",
  };
  proxy.$axios.post("/Index/getGames", params).then((res) => {
    if (res.code == 200) {
      gameList.value = res.data;
      // 获取展示游戏列表
      if (ifH5()) {
        totalPage.value = Math.ceil(gameList.value.length / 6);
        getShowList(6);
      } else {
        totalPage.value = Math.ceil(gameList.value.length / 8);
        getShowList(8);
      }
    }
  });
};
// 轮播
const startAutoplay = () => {
  swipeTimer.value = setInterval(() => {
    if (indexImg.value < bannerList.value.length - 1) {
      indexImg.value = indexImg.value + 1;
    } else {
      indexImg.value = 0;
    }
    toSwipe(indexImg.value == 0 ? bannerList.value.length : indexImg.value);
  }, autoplay.value);
};
const onChange = (index) => {
  if (swipeTimer.value) {
    clearInterval(swipeTimer.value);
    startAutoplay();
  }
  styleKey.value = styleKey.value + 1;
  indexImg.value = index;
};
const toSwipe = (index) => {
  swipe.value.swipeTo(index);
};
const bannerPrev = () => {
  swipe.value.prev();
};
const bannerNext = () => {
  swipe.value.next();
};
// 查看更多游戏
const getMore = () => {
  page.value = page.value + 1;
  if (ifH5()) {
    getShowList(6);
  } else {
    getShowList(8);
  }
};
// 鼠标滑动
// 鼠标滑动监测
const handleWheel = (e) => {
  if (ifH5()) return;
  if (canRun.value) {
    canRun.value = false;
    goScroll(e);
    setTimeout(() => {
      canRun.value = true;
    }, 100);
  }
};
const goScroll = (e) => {
  //e.wheelDelta 用来判断上一个下一个 <0 下一个 >0上一个
  if (e.wheelDelta < 0) {
    next();
  }
};
// 下一个
const next = () => {
  if (
    window.visualViewport.pageTop >=
    document.getElementById(`section1`).offsetHeight * 0.1
  ) {
    if (styleKey2.value > 0) return;
    styleKey2.value++;
  }
};
onMounted(async () => {
  window.addEventListener("wheel", handleWheel, { passive: false });
  await initData();
  store.commit("setLoad", false);
  startAutoplay();
});

onUnmounted(() => {
  window.removeEventListener("wheel", handleWheel);
  clearInterval(swipeTimer.value);
});
// 跳转到游戏官网
const handleGoOffice = (item) => {
  if (!item.game_url) {
    proxy.$message("敬请期待！");
    return;
  }
  window.location = item.game_url;
};
</script>
<style src="./game.css" scoped></style>
<style scoped>
</style>
