import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import * as ElIcons from '@element-plus/icons-vue'
import App from './App.vue'
import router from './router'
import store from './store';
import {api} from '@/utils/request'
import '@/utils/rem'
import Vant from 'vant';
import 'vant/lib/index.css';
import 'vant/es/toast/style';

import './assets/css/common.css'
const app = createApp(App)
app.config.globalProperties.$axios = api;
for (const iconName in ElIcons) {
    app.component(iconName, ElIcons[iconName])
}

app.use(store)
app.use(ElementPlus,{
    locale: zhCn,
})
app.use(Vant)
app.use(router)
app.mount('#app')
